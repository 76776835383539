.awssld__content > div:first-child > * {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; 
}

.awssld__content > div:first-child > *.project-tagline {
    opacity: .75; 
}

.awssld__content > div:first-child > *.project-tags {
    opacity: .82; 
}

.awssld__content > div:first-child > *.project-links {
    opacity: .82; 
}
  
.awssld__content > div:first-child > * {
    -webkit-transition: opacity 0.35s ease-out, -webkit-transform 0.45s cubic-bezier(0.15, 0.3, 0.15, 1);
    transition: opacity 0.35s ease-out, -webkit-transform 0.45s cubic-bezier(0.15, 0.3, 0.15, 1);
    transition: transform 0.45s cubic-bezier(0.15, 0.3, 0.15, 1), opacity 0.35s ease-out;
    transition: transform 0.45s cubic-bezier(0.15, 0.3, 0.15, 1), opacity 0.35s ease-out, -webkit-transform 0.45s cubic-bezier(0.15, 0.3, 0.15, 1); 
}
  
.awssld__content > div:first-child > *:nth-child(2) {
    -webkit-transition-delay: 0.05s, 0.05s;
    transition-delay: 0.05s, 0.05s; }
  
.awssld__content > div:first-child > *:nth-child(3) {
    -webkit-transition-delay: 0.10s, 0.10s;
            transition-delay: 0.10s, 0.10s; }
  
  .awssld__content > div:first-child > *:nth-child(4) {
    -webkit-transition-delay: 0.15s, 0.15s;
            transition-delay: 0.15s, 0.15s; }
  
  .awssld__content > div:first-child > *:nth-child(5) {
    -webkit-transition-delay: 0.20s, 0.20s;
            transition-delay: 0.20s, 0.20s; }
  
  .awssld__content > div:first-child > *:nth-child(6) {
    -webkit-transition-delay: 0.25s, 0.25s;
            transition-delay: 0.25s, 0.25s; }
  
  .awssld__content > div:first-child > *:nth-child(7) {
    -webkit-transition-delay: 0.30s, 0.30s;
            transition-delay: 0.30s, 0.30s; }
  
  .awssld__content > div:first-child > *:nth-child(8) {
    -webkit-transition-delay: 0.35s, 0.35s;
            transition-delay: 0.35s, 0.35s; }
  
  .awssld__content > div:first-child > *:nth-child(9) {
    -webkit-transition-delay: 0.40s, 0.40s;
            transition-delay: 0.40s, 0.40s; }
  
  .awssld__content > div:first-child > *:nth-child(10) {
    -webkit-transition-delay: 0.45s, 0.45s;
            transition-delay: 0.45s, 0.45s; }
  
  .awssld__content > div:first-child > *:nth-child(11) {
    -webkit-transition-delay: 0.50s, 0.50s;
            transition-delay: 0.50s, 0.50s; }
  
  .awssld__content > div:first-child > *:nth-child(12) {
    -webkit-transition-delay: 0.55s, 0.55s;
            transition-delay: 0.55s, 0.55s; }
  
  .awssld__content > div:first-child > *:nth-child(13) {
    -webkit-transition-delay: 0.60s, 0.60s;
            transition-delay: 0.60s, 0.60s; }
  
  .awssld__content > div:first-child > *:nth-child(14) {
    -webkit-transition-delay: 0.65s, 0.65s;
            transition-delay: 0.65s, 0.65s; }
  
  .awssld__content > div:first-child > *:nth-child(15) {
    -webkit-transition-delay: 0.70s, 0.70s;
            transition-delay: 0.70s, 0.70s; }
  
  .awssld__content > div:first-child > *:nth-child(16) {
    -webkit-transition-delay: 0.75s, 0.75s;
            transition-delay: 0.75s, 0.75s; }
  
  .awssld__content > div:first-child > *:nth-child(17) {
    -webkit-transition-delay: 0.80s, 0.80s;
            transition-delay: 0.80s, 0.80s; }
  
  .awssld__content > div:first-child > *:nth-child(18) {
    -webkit-transition-delay: 0.85s, 0.85s;
            transition-delay: 0.85s, 0.85s; }
  
  .awssld__content > div:first-child > *:nth-child(19) {
    -webkit-transition-delay: 0.90s, 0.90s;
            transition-delay: 0.90s, 0.90s; }
  
  .awssld__content > div:first-child > *:nth-child(20) {
    -webkit-transition-delay: 0.95s, 0.95s;
            transition-delay: 0.95s, 0.95s; }
  
  .awssld__content > div:first-child > *:nth-child(21) {
    -webkit-transition-delay: 0.100s, 0.100s;
            transition-delay: 0.100s, 0.100s; }
  
  .awssld__content > div:first-child > *:nth-child(22) {
    -webkit-transition-delay: 0.105s, 0.105s;
            transition-delay: 0.105s, 0.105s; }
  
  .awssld__content--static > div:first-child > *:nth-child(n) {
    -webkit-transition: none !important;
    transition: none !important; 
    }
  
  .awssld__content--exit > div:first-child > *:nth-child(n) {
    will-change: transform;
    -webkit-transition: opacity 0.15s ease-in 0.1s, -webkit-transform 0.225s cubic-bezier(0.25, 0, 0.85, 1);
    transition: opacity 0.15s ease-in 0.1s, -webkit-transform 0.225s cubic-bezier(0.25, 0, 0.85, 1);
    transition: transform 0.225s cubic-bezier(0.25, 0, 0.85, 1), opacity 0.15s ease-in 0.1s;
    transition: transform 0.225s cubic-bezier(0.25, 0, 0.85, 1), opacity 0.15s ease-in 0.1s, -webkit-transform 0.225s cubic-bezier(0.25, 0, 0.85, 1); }
  
  .awssld__content--exit > div:first-child > *:nth-child(2) {
    -webkit-transition-delay: 0.06s, 0.16s;
            transition-delay: 0.06s, 0.16s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(3) {
    -webkit-transition-delay: 0.12s, 0.22s;
            transition-delay: 0.12s, 0.22s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(4) {
    -webkit-transition-delay: 0.18s, 0.28s;
            transition-delay: 0.18s, 0.28s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(5) {
    -webkit-transition-delay: 0.24s, 0.34s;
            transition-delay: 0.24s, 0.34s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(6) {
    -webkit-transition-delay: 0.30s, 0.40s;
            transition-delay: 0.30s, 0.40s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(7) {
    -webkit-transition-delay: 0.36s, 0.46s;
            transition-delay: 0.36s, 0.46s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(8) {
    -webkit-transition-delay: 0.42s, 0.52s;
            transition-delay: 0.42s, 0.52s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(9) {
    -webkit-transition-delay: 0.48s, 0.58s;
            transition-delay: 0.48s, 0.58s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(10) {
    -webkit-transition-delay: 0.54s, 0.64s;
            transition-delay: 0.54s, 0.64s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(11) {
    -webkit-transition-delay: 0.60s, 0.70s;
            transition-delay: 0.60s, 0.70s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(12) {
    -webkit-transition-delay: 0.66s, 0.76s;
            transition-delay: 0.66s, 0.76s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(13) {
    -webkit-transition-delay: 0.72s, 0.82s;
            transition-delay: 0.72s, 0.82s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(14) {
    -webkit-transition-delay: 0.78s, 0.88s;
            transition-delay: 0.78s, 0.88s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(15) {
    -webkit-transition-delay: 0.84s, 0.94s;
            transition-delay: 0.84s, 0.94s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(16) {
    -webkit-transition-delay: 0.90s, 0.100s;
            transition-delay: 0.90s, 0.100s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(17) {
    -webkit-transition-delay: 0.96s, 0.106s;
            transition-delay: 0.96s, 0.106s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(18) {
    -webkit-transition-delay: 0.102s, 0.112s;
            transition-delay: 0.102s, 0.112s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(19) {
    -webkit-transition-delay: 0.108s, 0.118s;
            transition-delay: 0.108s, 0.118s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(20) {
    -webkit-transition-delay: 0.114s, 0.124s;
            transition-delay: 0.114s, 0.124s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(21) {
    -webkit-transition-delay: 0.120s, 0.130s;
            transition-delay: 0.120s, 0.130s; }
  
  .awssld__content--exit > div:first-child > *:nth-child(22) {
    -webkit-transition-delay: 0.126s, 0.136s;
            transition-delay: 0.126s, 0.136s; }
  
  .awssld__content--moveLeft > div:first-child > *:nth-child(n) {
    -webkit-transform: translate3d(-125px, 0, 0);
            transform: translate3d(-125px, 0, 0);
    opacity: 0; }
  
  .awssld__content--moveRight > div:first-child > *:nth-child(n) {
    -webkit-transform: translate3d(125px, 0, 0);
            transform: translate3d(125px, 0, 0);
    opacity: 0; }
  
  