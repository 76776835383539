@import '../node_modules/reset-css/reset.css';


html {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
* {
    box-sizing:border-box;  
}
::-webkit-scrollbar {
    width: 8px;
}
